<!--
 * @Author: chenjx
 * @Date: 2023-02-24 15:30:01
 * @LastEditors: chenjx
 * @LastEditTime: 2023-03-05 20:12:30
 * @Description: file content
 * @FilePath: \aitesi\src\components\Header.vue
-->
<template>
  <div>
    <div class="header header2" :class="{scrollBg: isScroll}">
      <div class="logo"><img :src="logo" alt="" @click="$router.push({path: '/'})" /></div>
      <ul class="menu">
        <li v-for="item in routerData" :key="item.id">
          <RouterLink :to="`${item.channel_type}`">{{ item.title }}</RouterLink>
          <ul class="menu2" v-if="item.subs && item.subs.length > 0">
            <div class="icon">
              <img src="../assets/images/icon10.png" alt="" />
            </div>
            <li v-for="child in item.subs" :key="child.id">
              <!-- {{ `${child.channel_type}/${child.id}` }} -->
              <RouterLink :to="`${child.channel_type}/${encodeURIComponent(child.id)}?id=${encodeURIComponent(item.id)}`">{{
                child.title
              }}</RouterLink>
            </li>
          </ul>
        </li>
      </ul>
      <div class="menu_icon hidden-md hidden-lg">
        <!-- <img src="../assets/images/menu_icon.png" alt="" /> -->
        <i class="el-icon-menu"></i>
      </div>
      <div class="input" v-if="this.$route.name === 'News'">
        <input type="text" v-model="valueQ" @keypress.enter.stop.prevent="goToNews" />
        <div class="input_icon">
          <img src="../assets/images/icon9.png" alt="" @click="goToNews" />
        </div>
      </div>
    </div>
    <!--手机弹出导航-->
    <ul class="wap_menu hidden-md hidden-lg">
      <li class="menu_tit"><span class="el-icon-close"></span> MENU</li>
      <li class="menu_lists" v-for="item in routerData" :key="item.id">
        <div class="wap_menu1">
          <p class="left">
            <RouterLink :to="`${item.channel_type}`">{{ item.title }}</RouterLink>
          </p>
          <i class="menu-right el-icon-arrow-right" v-if="item.subs && item.subs.length > 0"></i>
        </div>
        <div class="wap_menu2" v-if="item.subs && item.subs.length > 0">
          <RouterLink :to="`${child.channel_type}/${encodeURIComponent(child.id)}?id=${encodeURIComponent(item.id)}`" v-for="child in item.subs" :key="child.id">
            <i></i>
            <span class="sl">{{ child.title }}</span>
          </RouterLink>
        </div>
      </li>
    </ul>

    <!--手机弹出导航时，黑色屏罩-->
    <div class="black_cloth hidden-lg hidden-md"></div>
  </div>
</template>
<script>
import $ from 'jquery'
import { mapMutations } from 'vuex'

export default {
  props: {
    routerData: {
      default: () => [
        {
          id: "1",
          title: "首页",
          channel_type: "/index",
          image: "",
          subs: [],
        },
        {
          id: "5",
          title: "产品服务",
          channel_type: "/product",
          image: "",
          subs: [
            {
              id: "6",
              title: "卡片视频",
              channel_type: "/product/card",
              image: "",
            },
            {
              id: "7",
              title: "视频短信",
              channel_type: "/product/video",
              image: "",
            },
            {
              id: "8",
              title: "短信",
              channel_type: "/product/message",
              image: "",
            },
          ],
        },
        {
          id: "2",
          title: "文档中心",
          channel_type: "/word",
          image: "",
          subs: [],
        },
        {
          id: "3",
          title: "新闻动态",
          channel_type: "/news",
          image: "",
          subs: [],
        },
        {
          id: "4",
          title: "关于我们",
          channel_type: "/about",
          image: "",
          subs: [],
        },
      ],
      required: true,
      type: Array
    },
    logo: {
      default: () => require('../assets/images/logo.png'),
      required: true,
      type: String
    }
  },
  data() {
    return {
      valueQ: '',
      isScroll: ''
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrolling)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolling)
  },
  watch: {
    routerData() {
      this.$nextTick(() => {
        this.init()
      })
    }
  },
  methods: {
    ...mapMutations(['setQ']),
    goToNews() {
      this.setQ(this.valueQ)
      if(this.$route.name !== 'News') {
        const menu = JSON.parse(sessionStorage.getItem('menu'))
        this.$router.push({
          path: `/news?id=${menu['/news']}&q=${this.valueQ}`
        })
      }
    },
    scrolling() {
      let scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.isScroll = scroll > 10
    },
    init() {
      $(".menu>li").hover(function(){
          $(this).find(".menu2").show();
      },function(){
          $(this).find(".menu2").hide();
      })
      // 手机左边弹出导航
      $(".menu_icon,.navigation").click(function () {
          $(".black_cloth").show();
          //滑开导航动态效果  建议：rotateInDownRight,flipInX,bounceInLeft,fadeInDown
          $(".wap_menu li").each(function(i){ 
              $(this).addClass("animated  fadeInDown")
              i=i+1
              $(this).css("animation-delay",i/10+"s") }) 
      $(".wap_menu").animate({ "top": "0" }, 200)/*从左滑进 "left": "0"*//*从右边滑进 "right": "0"*//*从上边滑进 "top": "0"*/
      })
      // 手机左边弹出导航，点击一级分类展开二级分类
      $(".wap_menu>li.menu_lists>.wap_menu1>i.menu-right").click(function () {
          if ($(this).parent().siblings(".wap_menu2").css("display") == "block") {
              $(this).parents(".menu_lists").find(".wap_menu2").slideUp();
              $(this).removeClass("menu-click");
              return;
          }
          $(".wap_menu li.menu_lists .wap_menu1 i.menu-right").removeClass("menu-click");
          $(".wap_menu2").slideUp();
          $(this).addClass("menu-click");
          $(this).parent().siblings(".wap_menu2").slideDown();
      })
      $(".menu_tit span,.black_cloth").click(function(){
          $(".wap_menu li").each(function(){ 
                  $(this).removeClass("animated  fadeInDown")
          })
          
          $(".wap_menu>li.menu_lists>.wap_menu1>i.menu-right a").removeClass("menu-click");
          $(".wap_menu2").slideUp();

          $(".black_cloth").hide();
        $(".wap_menu").animate({ "top": "-100%" }, 200)   /*从左滑进 "left": "-250"*//*从右边滑进 "right": "-250"*//*从上边滑进 "top":"-100%"*/
      })
      $(document).scroll(function(){
          const top=$(document).scrollTop();
          if(top>0){
              $(".header").addClass("fix");
          }else{
              $(".header").removeClass("fix");
          }
      })
    }
  }
};
</script>
<style scoped lang="less">
div .header.header2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.5s linear;
  &::after {
    clear: 'none';
  }
}
div .header.header2.scrollBg {
  background-color: rgba(0, 0, 0, 0.65);
}
.menu-right {
  position: absolute;
  top: 14px;
  right: 15px;
  font-size: 20px;
  transition: all 0.3s linear 0s;
  transform: rotate(0deg);
}
.menu-click {
  transform: rotate(90deg);
}
.menu_icon {
  font-size: 23px;
  color: #FFF;
  margin-top: 0;
}
</style>